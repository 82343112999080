<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable('kWh', 'kWh', '0', {
          component: TSelectableWithTextField,
          textField: true,
        }),
        new Selectable(
          'Je ne peux pas effectuer une estimation',
          'Je ne peux pas effectuer une estimation',
          'Je ne peux pas effectuer une estimation',
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 personne)',
          '2.300 kWh (1 personne)',
          '2.300 kWh (1 personne)',
        ),
        new Selectable(
          '3.400 kWh (2 personnes)',
          '3.400 kWh (2 personnes)',
          '3.400 kWh (2 personnes)',
        ),
        new Selectable(
          '4.100 kWh (3 personnes)',
          '4.100 kWh (3 personnes)',
          '4.100 kWh (3 personnes)',
        ),
        new Selectable(
          '4.600 kWh (4 personnes)',
          '4.600 kWh (4 personnes)',
          '4.600 kWh (4 personnes)',
        ),
        new Selectable(
          '5.300 kWh (5 personnes)',
          '5.300 kWh (5 personnes)',
          '5.300 kWh (5 personnes)',
        ),
        new Selectable(
          '5.400 kWh (6 personnes)',
          '5.400 kWh (6 personnes)',
          '5.400 kWh (6 personnes)',
        ),
        new Selectable(
          'Plus de 5.400 kWh',
          'Plus de 5.400 kWh',
          'Plus de 5.400 kWh',
        ),
        new Selectable(
          'Je ne peux pas effectuer une estimation',
          'Je ne peux pas effectuer une estimation',
          'Je ne peux pas effectuer une estimation',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      let marketSegment = this.$store.getters['lead/getData']('market-segment')
      if (typeof marketSegment === 'object' && marketSegment[0]?.value) {
        marketSegment = marketSegment[0].value
      }

      if (marketSegment === this.$t('field.marketSegment.consumer')) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
