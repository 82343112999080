<template>
  <div class="service-form-part">
    <MarketSegmentRadioFormPart :choices="marketSegments" v-on="$listeners" />

    <h2 class="form-modal__part-title">
      {{ $t('steps.typeOfAssignment') }}
    </h2>

    <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
  </div>
</template>

<script>
import {
  solarPanelsPurchaseBE,
  solarPanelsPurchaseSolarCollectorsBE,
} from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import MarketSegmentRadioFormPart from 'chimera/all/components/form/parts/marketSegment/MarketSegmentRadioFormPart.vue'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
    MarketSegmentRadioFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegments: [
        new Selectable(
          'Maison résidentielle',
          'Maison résidentielle',
          this.$t('field.marketSegment.consumer'),
        ),
        new Selectable(
          'Immeuble commercial',
          'Immeuble commercial',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          'Entreprise agricole',
          'Entreprise agricole',
          this.$t('field.marketSegment.corporateValue'),
        ),
        new Selectable(
          "Grands projets d'entreprise",
          "Grands projets d'entreprise",
          this.$t('field.marketSegment.corporateValue'),
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Panneaux solaires',
          'Panneaux solaires',
          solarPanelsPurchaseBE.id,
        ),
        new Selectable(
          'Chaudière solaire / Capteurs solaires',
          'Chaudière solaire / Capteurs solaires',
          solarPanelsPurchaseSolarCollectorsBE.id,
        ),
      ],
    }
  },
}
</script>
